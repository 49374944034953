import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import landverndLogo from "../images/landvernd-cropped.png";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

const IndexPage = props => (
  <Layout>

    <SEO title="Home" />
    
    <a href="/join" className="ils-btn ils-btn--fixed">Join</a>
    <a href="https://teespring.com/stores/iceberg-licking-society" target="_blank" rel="noopener noreferrer" className="ils-btn ils-btn--fixed-left">Shop</a>
    <div className="landvernd-container">
      <p className="small-heading">Official Partner:</p>
      <a href="https://landvernd.is/en-landvernd-icelandic-environment-association/" target="_blank" rel="noopener noreferrer"><img src={landverndLogo} alt="Landvernd" id="landvernd-logo" /></a>
    </div>

    <section>
      <div className="video-container">
        <iframe title="ils film" width="100%" height="100%" src="https://www.youtube.com/embed/NjQ6e7Mdp4E" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
      </div>
    </section>

    <section>
      <h3 className="section-heading">About</h3>
      <p className="text">Iceberg Licking Society is a community of like-minded individuals that travel all over the world in search of quality icebergs to lick.</p>
    </section>

    <section>
      <div style={{maxWidth: 600, margin: "0 auto" }}>
        <Img fluid={props.data.imageOne.childImageSharp.fluid} />
      </div>
    </section>

    <section>
      <p className="text">"The feeling of licking something over 15,000 years old really grounds you as a human being, bringing forth newfound energy, straight from Mother 
      Nature herself." - Gregor</p>
    </section>

    <section>
      <h3 className="section-heading">Global Warming</h3>
      <p className="text">ILS have witnessed a steady, decade-long decline of lick-able opportunities due to global warming. Senior members report that the sensation of licking 
        an iceberg is not what it used to be. Rising temperatures around the world have made ice wetter and warmer to the touch, which is endangering the 
        desirability and satisfaction of the iceberg lick.</p>
    </section>

    <section>
      <h3 className="section-heading">Our Mission</h3>
      <p className="text">Reimagining what society can do at-large to help preserve icebergs, therefore ensuring current and future lickers' rights. Join us if you, too, seek to 
        protect the iceberg licking experience.</p>
    </section>

    <section>
      <h3 className="section-heading">Charity Partner</h3>
      <p className="text">ILS are proud to have partnered with <a href="https://landvernd.is/en-landvernd-icelandic-environment-association/" target="_blank" rel="noopener noreferrer">Landvernd</a>, the leading environmental NGO in Iceland.
        In the last two decades, Landvernd’s efforts have increasingly concentrated on protecting Iceland’s unique wilderness and landscapes. The ILS SHOP will be donating half of all proceeds to all the work that Landvernd is doing to combat environmental issues, whilst the other half will be allocated to ILS for future documentation of the society’s inner-workings.
      </p>
    </section>

    <section>
      <a href="/join" className="ils-btn" style={{lineHeight: 3}}>Join ILS</a>
    </section>

  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "gregor-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;